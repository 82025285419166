import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Drawer
} from '@mui/material';
import GCMenu from "../../../common/components/GCMenu";
import {GreenChartTooltip} from "../../../common/components/GreenChartTooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import {usePattern} from "../../../hooks/usePattern";
import {useShowcaseContext} from "../contexts/ShowcaseContext";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from "@mui/material/Box";
import "./ShowcaseTable.css";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, doc, query, setDoc, deleteDoc, where} from "firebase/firestore";
import {firebase_firestore} from "../../../common/firebaseConfig";
import {useTheme} from "@mui/material/styles";
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined';
import ShowcaseHeaderBar from "./ShowcaseHeaderBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidebar from "../../../layout/Sidebar";
import ShowcaseSidebar from "../app/ShowcaseSidebar";

const BacktestTable: React.FC = () => {
    const theme = useTheme();

    const {
        setBacktestSettingInputData,
        setActiveTab,
        setPattern,
        canShowcaseViewBacktestSettings,
        tradingHouse,
        usermeta
    } = useApplicationSuiteContext();

    const {
        showcaseBacktestCount,
        showcaseBacktests,
        showcaseBacktestsLoading,
        showcaseSavedOnlyFilter,
        take,
        setTake,
        skip,
        setSkip,
        sort,
        setSort,
    } = useShowcaseContext();

    const [backtestSettings, setActiveBacktestSettings] = useState<any>();
    const [pattern, patternRef] = usePattern(backtestSettings?.patternId, backtestSettings?.patternVersion, backtestSettings?.gcid);
    const [savedSummaries] = useCollectionData(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`), where("backtestSettings.tradingHouse", "==", tradingHouse))) as any[];

    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        if (pattern && patternRef) {
            pattern.author = "Showcase";
            setPattern(pattern);
            setActiveTab(0);
        }
    }, [pattern, patternRef, setActiveTab, setPattern]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setSkip(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTake(parseInt(event.target.value, 10));
        setSkip(0);
    };

    function viewSettings(_backtestSettings: any, gcid: string) {
        _backtestSettings.gcid = gcid
        setBacktestSettingInputData(_backtestSettings);
        setActiveTab(1);
    }

    function viewPattern(_backtestSettings: any, gcid: string) {
        _backtestSettings.gcid = gcid
        setActiveBacktestSettings(_backtestSettings);
    }

    const handleAddToSavedShowcase = async (summary: any) => {
        const savedShowcaseRef = collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`);
        try {
            await setDoc(doc(savedShowcaseRef, summary.id), summary);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleRemoveFromSavedShowcase = async (summaryId: string) => {
        const savedShowcaseRef = collection(firebase_firestore, `usermeta/${usermeta.gcid}/savedShowcase`);
        await deleteDoc(doc(savedShowcaseRef, summaryId));
    };

    const handleSort = (field: string) => {
        const isDesc = sort.field === field && sort.direction === 'desc';
        setSort({
            field: field,
            direction: isDesc ? 'asc' : 'desc'
        });
    };

    const summaryList: any[] = showcaseSavedOnlyFilter
        ? (savedSummaries || [])
        : (showcaseBacktests || [])

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper sx={{width: "100%"}}>
            <ShowcaseHeaderBar
                setFilterOpen={setFilterOpen}
                filterOpen={filterOpen}
            />

            <Box
                display={'flex'}
                flexDirection={'row'}
                sx={{height: "calc(100% - 80px)"}}
            >
                {!isMobile && <Sidebar>
                    <ShowcaseSidebar/>
                </Sidebar>}

                {isMobile && <Drawer
                    open={filterOpen}
                    anchor="right"
                    onClose={() => setFilterOpen(false)}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}} p={3}>
                        <ShowcaseSidebar/>
                    </Box>
                </Drawer>}

                <Box
                    sx={{width: `100%`}}
                >

                    {/* THE CALCULATION BELOW MIGHT NEED TO BE MODIFIED FOR HEIGHT */}
                    {(!showcaseBacktestsLoading && summaryList.length > 0) && <TableContainer sx={{height: "calc(100% - 52px)"}}>
                      <Table>
                        <TableHead
                          sx={{
                              backgroundColor: theme.palette.background.custom2,
                              position: 'sticky',
                              top: 0,
                          }}
                        >
                          <TableRow>
                            <TableCell />
                            <TableCell>Pattern</TableCell>
                            <TableCell>Ratio (risk/reward)</TableCell>
                            <TableCell>
                              <TableSortLabel
                                active={sort.field === 'time'}
                                direction={sort.field === 'time' ? sort.direction : 'desc'}
                                onClick={() => handleSort('time')}
                              >
                                Date Backtested
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>Initial Balance</TableCell>
                            <TableCell>
                              <TableSortLabel
                                active={sort.field === 'profitNumber'}
                                direction={sort.field === 'profitNumber' ? sort.direction : 'desc'}
                                onClick={() => handleSort('profitNumber')}
                              >
                                Net Profit (%)
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel
                                active={sort.field === 'abRelativeDrawdownNumber'}
                                direction={sort.field === 'abRelativeDrawdownNumber' ? sort.direction : 'desc'}
                                onClick={() => handleSort('abRelativeDrawdownNumber')}
                              >
                                AB Relative Drawdown (%)
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel
                                active={sort.field === 'equityDrawdownNumber'}
                                direction={sort.field === 'equityDrawdownNumber' ? sort.direction : 'desc'}
                                onClick={() => handleSort('equityDrawdownNumber')}
                              >
                                Equity Drawdown (%)
                              </TableSortLabel>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                            {summaryList
                                .map((summary: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: theme.palette.background.custom3,
                                        }}
                                    >

                                        {
                                            (savedSummaries || []).find((savedSummary: any) => savedSummary.id === summary.id) ?
                                                <TableCell>
                                                    <GreenChartTooltip arrow title={"Remove Showcase from favorited list"}>
                                                        <StarIcon
                                                            sx={{ color: '#00a1ff', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveFromSavedShowcase(summary.id)}
                                                        />
                                                    </GreenChartTooltip>
                                                </TableCell> :
                                                <TableCell>
                                                    <GreenChartTooltip arrow title={"Favorite Showcase to find later"}>
                                                        <StarBorderIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    color: '#00a1ff',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            onClick={() => handleAddToSavedShowcase(summary)}
                                                        />
                                                    </GreenChartTooltip>
                                                </TableCell>
                                        }
                                        <TableCell sx={{maxWidth: 250}}>
                                            <Typography variant={"body1"}>{summary.patternName} <Typography variant={"caption"}>(v{summary.backtestSettings.patternVersion})</Typography></Typography>
                                            <Typography variant={"caption"}>{summary.backtestSettings.executionSymbol}</Typography>
                                            <br/>
                                            {usermeta.isAdmin && <Typography variant={"caption"}>{summary.backtestId}</Typography>}
                                        </TableCell>
                                        <TableCell>{summary['Ratio (risk/reward)']}</TableCell>
                                        <TableCell>{new Date(summary.time).toLocaleDateString()}</TableCell>
                                        <TableCell>{summary['Initial Balance']}</TableCell>
                                        <TableCell>{summary.profit}</TableCell>
                                        <TableCell>{summary['AB Relative Drawdown (%)']}</TableCell>
                                        <TableCell>{summary['Equity Drawdown (%)']}</TableCell>
                                        <TableCell sx={{cursor: 'pointer'}}>
                                            <GCMenu
                                                title={<GreenChartTooltip arrow
                                                                          title={"More..."}><MoreVertIcon/></GreenChartTooltip>}
                                                items={[
                                                    {
                                                        label: 'View pattern',
                                                        action: () => viewPattern(summary.backtestSettings, summary.backtestSettings.gcid || summary.gcid),
                                                        icon: <QueryStatsOutlinedIcon />,
                                                    },
                                                    {
                                                        label: 'View backtest settings',
                                                        action: () => viewSettings(summary.backtestSettings, summary.backtestSettings.gcid || summary.gcid),
                                                        icon: <VideoSettingsOutlinedIcon />,
                                                        locked: !canShowcaseViewBacktestSettings
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                      </Table>
                    </TableContainer>}
                    {
                        !showcaseBacktestsLoading && summaryList.length === 0 &&
                      <Box sx={{
                          fontSize: '1.2rem',
                          display: 'flex',
                          height: "calc(100vh - 82px)",
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                      }}>
                        <Typography variant={"h5"}>No showcased Backtests found. </Typography>
                        <Typography variant={"body1"}>Try adjusting your filters.</Typography>
                      </Box>
                    }
                    {
                        showcaseBacktestsLoading &&
                      <Box sx={{
                          fontSize: '1.2rem',
                          display: 'flex',
                          height: "calc(100vh - 82px)",
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column'
                      }}>
                        <Typography className="loading" variant={"h5"}>Searching the Showcase</Typography>
                      </Box>
                    }
                    {
                        (!showcaseBacktestsLoading && summaryList.length > 0) && <TablePagination
                        sx={{
                            backgroundColor: theme.palette.background.custom2
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={showcaseSavedOnlyFilter ? (savedSummaries || []).length : showcaseBacktestCount}
                        rowsPerPage={take}
                        page={skip}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    }
                </Box>

            </Box>
        </Paper>

    );
};

export default BacktestTable;
