import React, {useState, useEffect, useContext} from "react";
import { Paper, Typography, FormControl, Select, MenuItem, TextField, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";  // Exact
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline"; // Some
import PieChartIcon from "@mui/icons-material/PieChart";  // All
import {PatternTreeContext} from "../../contexts/PatternTreeContext";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";

const PreviousCandleSettings = ({ condition, sourceGroupId }: any) => {
    const theme = useTheme();

    const maxPreviousCandles = 50;

    // State to track the select value (Exact, Some, All)
    const [selectedOption, setSelectedOption] = useState(0);

    const {
        handleChangeCondition,
    } = useContext(PatternTreeContext);

    // Update selectedOption based on candlesNeeded and numberOfCandles
    useEffect(() => {

        if (condition.settings?.previousCandle?.numberOfCandles === undefined || condition.settings?.previousCandle?.numberOfCandles === null) {
            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.numberOfCandles", 1);
            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", 0);
            setSelectedOption(0); // Exact
        } else if (condition.settings?.previousCandle?.candlesNeeded === 0) {
            setSelectedOption(0);  // Exact
        } else if (condition.settings?.previousCandle?.candlesNeeded === condition.settings?.previousCandle?.numberOfCandles) {
            setSelectedOption(2);  // All
        } else {
            setSelectedOption(1);  // Some
        }
    }, [condition.settings?.previousCandle, sourceGroupId, condition.id, handleChangeCondition]);

    const handleSelectChange = (value: any) => {
        setSelectedOption(value);

        if (value === 0) {
            // Exact selected
            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", 0);
        } else if (value === 1) {
            // Some selected
            if (condition.settings?.previousCandle?.numberOfCandles === 1) {
                handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.numberOfCandles", 2);
                handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", 1);
            } else {
                handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", 1);
            }
        } else if (value === 2) {
            // All selected
            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", condition.settings?.previousCandle?.numberOfCandles);
        }
    };

    function getPreviousCandleSettingsDescription() {
        if (selectedOption === 0) {
            return `The ${getOrdinalSuffix(condition.settings?.previousCandle?.numberOfCandles)} previous candle must meet the condition`;
        } else if (selectedOption === 1) {
            if (condition.settings?.previousCandle?.numberOfCandles === 1) {
                return `The previous candle must meet the condition`;
            } else {
                return `At least ${condition.settings?.previousCandle?.candlesNeeded} of the previous ${condition.settings?.previousCandle?.numberOfCandles} candles must meet the condition`;
            }
        } else {
            if (condition.settings?.previousCandle?.numberOfCandles === 1) {
                return `The previous candle must meet the condition`;
            } else {
                return `All ${condition.settings?.previousCandle?.numberOfCandles} previous candles must meet the condition`;
            }
        }
    }

    function getOrdinalSuffix(num: number) {
        const j = num % 10,
            k = num % 100;

        if (j === 1 && k !== 11) {
            return num + "st";
        }
        if (j === 2 && k !== 12) {
            return num + "nd";
        }
        if (j === 3 && k !== 13) {
            return num + "rd";
        }
        return num + "th";
    }

    return (
        <Paper sx={{ p: 2, maxWidth: 300, background: theme.palette.background.custom1, gap: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" gutterBottom>
                Previous Candle Settings
            </Typography>

            <Box
                display={'flex'}
                alignItems={'center'}
            >
                <Select
                    value={selectedOption}
                    renderValue={(value) => {
                        if (value === 0) {
                            return <CrisisAlertIcon />;
                        } else if (value === 1) {
                            return <PieChartOutlineIcon />;
                        } else {
                            return <PieChartIcon />;
                        }
                    }}
                    onChange={(e) => {
                        e.stopPropagation();
                        handleSelectChange(e.target.value)
                    }}
                    sx={{
                        height: 56,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                >
                    <MenuItem value={0} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <CrisisAlertIcon /> Exact
                    </MenuItem>
                    <MenuItem value={1} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <PieChartOutlineIcon /> Some
                    </MenuItem>
                    <MenuItem value={2} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        <PieChartIcon /> All
                    </MenuItem>
                </Select>

                <TextField
                    sx={{
                        m: 0,
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                    }}
                    label={`Previous candle to target`}
                    type={"number"}
                    inputProps={{ min: 1, max: maxPreviousCandles }}
                    fullWidth
                    margin="normal"
                    value={condition.settings?.previousCandle?.numberOfCandles}
                    onChange={(e) => {
                        e.stopPropagation();
                        const newNumberOfCandles = parseInt(e.target.value);
                        if (newNumberOfCandles < 1 || newNumberOfCandles > maxPreviousCandles) return;

                        if (
                            selectedOption === 1 // Some selected
                            && newNumberOfCandles <= condition.settings?.previousCandle?.candlesNeeded
                            && newNumberOfCandles > 1) {
                            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", newNumberOfCandles-1)
                        } else if (selectedOption === 2) { // All selected
                            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", newNumberOfCandles)
                        }


                        handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.numberOfCandles", newNumberOfCandles)}
                    }
                />

            </Box>


            {
                selectedOption === 1 &&
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                        {`${condition.settings?.previousCandle?.candlesNeeded}/${condition.settings?.previousCandle?.numberOfCandles} Candles needed to be met`}
                    </InputLabel>
                    <OutlinedInput
                        value={condition.settings?.previousCandle?.candlesNeeded === 0 ? "" : condition.settings?.previousCandle?.candlesNeeded}
                        onChange={(e) => {
                            const newCandlesNeeded = parseInt(e.target.value);
                            if (newCandlesNeeded > condition.settings?.previousCandle?.numberOfCandles) return;
                            handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.candlesNeeded", newCandlesNeeded);
                        }}
                        type={"number"}
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">#</InputAdornment>}
                        label={`${condition.settings?.previousCandle?.candlesNeeded}/${condition.settings?.previousCandle?.numberOfCandles} Candles needed to be met`}
                        inputProps={{min: 1, max: condition.settings?.previousCandle?.numberOfCandles - 1}}
                    />
                </FormControl>
            }

            <Typography variant="body2" color="textSecondary" gutterBottom>
                {getPreviousCandleSettingsDescription()}
            </Typography>
        </Paper>
    );
};

export default PreviousCandleSettings;
