import { useContext, useState, useEffect } from "react";
import { doc, collection, getDoc, setDoc, getDocs, query, orderBy, limit } from "firebase/firestore";
import * as jsondiffpatch from "jsondiffpatch";
import { PatternContext } from "../app/builder/contexts/PatternContext";
import {PatternTreeContext} from "../app/builder/contexts/PatternTreeContext";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import { firebase_firestore } from "../common/firebaseConfig";
import ReactGA from "react-ga4";

const useSavePattern = () => {
    const { pattern, setPattern, saveAsStandardPattern } = useContext(PatternContext);
    const { buyConditionGroups, sellConditionGroups } = useContext(PatternTreeContext);
    const { usermeta, tradingHouse, canViewPrivatePatterns } = useApplicationSuiteContext();

    const [versions, setVersions] = useState<any[]>([]);

    const fetchVersions = async (path: string) => {
        const q = query(collection(firebase_firestore, path), orderBy("version"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data());
    };

    useEffect(() => {
        const userDocPath = `usermeta/${usermeta.gcid}/patterns/${pattern.id}`;
        const userDocRef = doc(firebase_firestore, userDocPath);

        getDoc(userDocRef).then(docSnapshot => {
            const versionsPath = docSnapshot.exists() ?
                `${userDocPath}/versions` :
                `tradingHouses/${tradingHouse}/standardPatterns/${pattern.id}/versions`;

            return fetchVersions(versionsPath);
        }).then(docs => {
            setVersions(docs);
        });

    }, [usermeta.gcid, pattern.version, pattern.id, tradingHouse]);

    const savePattern = async () => {

        let patternLocation = saveAsStandardPattern ? `tradingHouses/${tradingHouse}/standardPatterns` : `usermeta/${usermeta.gcid}/patterns`

        let docRef = pattern.id
            ? doc(firebase_firestore, `${patternLocation}/${pattern.id}`)
            : doc(collection(firebase_firestore, patternLocation))

        const patternCopy = JSON.parse(JSON.stringify(pattern))

        patternCopy.buyConditionGroups = buyConditionGroups;
        patternCopy.sellConditionGroups = sellConditionGroups;
        undefinedToNull(patternCopy)

        if (patternCopy.id) {
            // pattern could be using the ID generated on the usermeta (or the standard) collection. If so, we need to create a new one to prevent duplicate IDs
            const existingDoc = await getDoc(doc(firebase_firestore, `${patternLocation}/${pattern.id}`))
            if (!existingDoc.exists()) {
                patternCopy.version = 1
                patternCopy.alertVersion = 1
                docRef = doc(collection(firebase_firestore, patternLocation))
            } else {
                // existing pattern does exist, so we need to increment the version number
                const previousVersion = versions && versions.length && versions[versions.length - 1].version ? versions[versions.length - 1].version : 1
                patternCopy.version = previousVersion + 1
            }
        }

        patternCopy.id = docRef.id
        patternCopy.active = true
        if (!saveAsStandardPattern) {
            if (usermeta.email && usermeta.email.includes("@")) {

                const userEmailPrefix = usermeta.email.split("@")[0];

                // every can save a pattern but the author needs to remain showcase if it is showcase if the user canViewPrivatePatterns
                // if the user can't view private patterns and it is a showcase pattern, then author remains showcase
                // if there is no author, then the author is the user

                if (!patternCopy.author) {
                    patternCopy.author = userEmailPrefix
                } else {
                    if (patternCopy.author === "Showcase" && !canViewPrivatePatterns) {
                        patternCopy.author = "Showcase"
                    } else {
                        patternCopy.author = userEmailPrefix
                    }
                }
            }
        } else {
            patternCopy.author = tradingHouse

            // if the pattern is being saved as a standard pattern and there's a layoutId then we need to add it to the sharedLayouts collection so everyone can use it
            if (patternCopy.layoutId && patternCopy.layoutId.trim !== "") {
                const existingLayout = await getDoc(doc(firebase_firestore, `usermeta/${usermeta.gcid}/layouts/${patternCopy.layoutId}`));
                if (existingLayout.exists()) {
                    const sharedLayoutsRef = doc(firebase_firestore, `sharedLayouts/${patternCopy.layoutId}`);
                    await setDoc(sharedLayoutsRef, existingLayout.data()!);
                }
            }
        }

        patternCopy.tradingHouse = tradingHouse;

        ReactGA.event('pattern_save', {
            author: patternCopy.author,
            pattern_name: patternCopy.name
        });

        await saveDiff(docRef, patternCopy)
        await setDoc(docRef, patternCopy)
        setPattern(patternCopy)
        // navigate(`/${patternCopy.id}`, { replace: true });
    };

    async function saveDiff(docRef: any, patternCopy: any) {
        const existingDoc = await getDoc(doc(firebase_firestore, docRef.path))
        let previousPattern = {}

        if (existingDoc.exists()) {

            // TEMP CODE
            const versionCollectionRef = await getDocs(query(collection(firebase_firestore, `${docRef.path}/versions`), orderBy("version"), limit(1))).then((querySnapshot) => querySnapshot.docs.map((doc) => doc.data()));
            if (!versionCollectionRef.length) {
                await setDoc(doc(firebase_firestore, `${docRef.path}/versions/1`), {
                    version: 1,
                    diff: JSON.stringify(jsondiffpatch.diff({}, existingDoc.data()!)),
                    created: new Date().getTime()
                })
            }
            // TEMP CODE

            previousPattern = existingDoc.data()!
        }

        const diff = jsondiffpatch.diff(previousPattern, patternCopy)

        if (diff) {

            const versionRef = doc(firebase_firestore, `${docRef.path}/versions/${patternCopy.version}`)
            const versionData = {
                version: patternCopy.version,
                diff: JSON.stringify(diff),
                created: new Date().getTime()
            }
            await setDoc(versionRef, versionData)
        }
    }


    function undefinedToNull(obj: any) {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === undefined) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    undefinedToNull(obj[key]);
                }
            }
        }

        return obj;
    }

    return { savePattern };
};

export default useSavePattern;
