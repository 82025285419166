import React, {createContext, useState} from "react";
import {v4 as uuidv4} from "uuid";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import {MAX_CONDITION_GROUP_DEPTH} from "../../../common/constants";
import ReactGA from "react-ga4";

export type ConditionType = {
    id: string;
    type: 'condition',
    chartDetails: {
        symbol: string,
        periodicity: number,
        timeframe: string,
    },
    condition: {
        LHS?: { field: string | "value", value?: any, indicator: {name: string, params?: {[key: string] : any} } },
        conditionType: string | undefined,
        RHS?: { field: string | "value", value?: any, indicator: { name: string, params?: {[key: string] : any} } },
        isValid?: boolean;
    }
    settings?: {
        previousCandle?: {
            enabled: boolean,
            numberOfCandles: number,
            candlesNeeded: number,
        }
    }
    sourceGroupId: string,
};

export type ConditionGroupType = {
    id: string;
    sourceGroupId?: string;
    groupTitle: string;
    type: 'group',
    conditions: ConditionType[];
    conditionGroups: ConditionGroupType[];
    depth: number,
    dropIndex?: number,
    count: number

    symbol: string;
    periodicity: number;
    timeframe: string;


};

export function PatternTreeProvider({children}: any) {
    const [buyConditionGroups, setBuyConditionGroups] = useState<ConditionGroupType[]>([]);
    const [sellConditionGroups, setSellConditionGroups] = useState<ConditionGroupType[]>([]);

    const [activeTab, setActiveTab] = useState('buy');

    const getActiveConditionGroups = (): [ConditionGroupType[], React.Dispatch<React.SetStateAction<ConditionGroupType[]>>] => {
        switch (activeTab) {
            case 'buy':
                return [buyConditionGroups, setBuyConditionGroups];
            case 'sell':
                return [sellConditionGroups, setSellConditionGroups];
        }
        return [[], () => {}];
    };

    const addConditionGroup = (conditionGroup?: ConditionGroupType) => {

        ReactGA.event('condition_group_add_to_root', {
            source: conditionGroup ? 'saved' : 'new'
        });

        const newGroup: ConditionGroupType = {
            type: 'group',
            groupTitle: ``,
            conditions: [],
            conditionGroups: [],
            count: 0,
            symbol: "EURUSD",
            periodicity: 1,
            timeframe: "minute",
            ...conditionGroup,
            depth: 1,
            id: uuidv4(),
            sourceGroupId: undefined,
        };

        updateGroupIds(newGroup);

        const [conditionGroups, setConditionGroups] = getActiveConditionGroups();
        setConditionGroups([...conditionGroups, newGroup]);
    };

    const handleAddNestedGroup = (groupId: string, conditionGroup?: ConditionGroupType) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_group_add_to_group', {
            source: conditionGroup ? 'saved' : 'new'
        });

        setConditionGroups((prevGroups) => {
            const { group: targetGroup } = findGroupAndParent(prevGroups, groupId)!;

            if (conditionGroup) {
                updateGroupIds(conditionGroup);
                let addedConditionGroupMaxDepth = findMaxDepth(conditionGroup);
                if (targetGroup.depth + addedConditionGroupMaxDepth > MAX_CONDITION_GROUP_DEPTH) {
                    return [...prevGroups]
                }
            }

            if (targetGroup.depth >= MAX_CONDITION_GROUP_DEPTH) {
                return [...prevGroups]
            }

            const newGroup: ConditionGroupType = {
                groupTitle: ``,
                type: 'group',
                symbol: targetGroup.symbol,
                periodicity: targetGroup.periodicity,
                timeframe: targetGroup.timeframe,
                conditions: [],
                conditionGroups: [],
                count: 0,
                ...conditionGroup,
                depth: targetGroup.depth+1,
                id: uuidv4(),
                sourceGroupId: groupId,
            };

            updateGroupIds(newGroup);

            return updateGroupInTree(prevGroups, groupId, (group) => {
                updateConditionGroupCount(group, 1);
                return {
                    ...group,
                    conditionGroups: group.conditionGroups.concat(newGroup)
                };
            });
        });
    };

    const findMaxDepth = (obj: ConditionGroupType, currentDepth = 1) => {
        // If there's no "groups" field or it's empty, return current depth.
        if (!obj.conditionGroups || obj.conditionGroups.length === 0) {
            return currentDepth;
        }

        // Otherwise, find the maximum depth of all child nodes and return it.
        let maxChildDepth = currentDepth;
        for (const group of obj.conditionGroups) {
            const childDepth = findMaxDepth(group, currentDepth + 1);
            if (childDepth > maxChildDepth) {
                maxChildDepth = childDepth;
            }
        }

        return maxChildDepth;
    };

    const handleRemoveConditionGroup = (groupId: string) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_group_remove');

        setConditionGroups((prevGroups) => {
            const { parentGroup } = findGroupAndParent(prevGroups, groupId)!;

            const updatedGroups = removeGroupAndChildren(prevGroups, groupId);


            if (parentGroup) {
                updateConditionGroupCount(parentGroup, -1);
                const { group: updatedParentGroup } = findGroupAndParent(updatedGroups, parentGroup.id)!;

                const groupMaxCount = (updatedParentGroup.conditionGroups?.length ?? 0) + (updatedParentGroup.conditions?.length ?? 0)
                if (updatedParentGroup.count >= groupMaxCount) {
                    updatedParentGroup.count = groupMaxCount;
                }
            }

            return _cloneDeep(updatedGroups);
        });
    };

    // Helper function to remove a group and its nested groups recursively
    const removeGroupAndChildren = (
        groups: ConditionGroupType[],
        groupId: string
    ): ConditionGroupType[] => {
        return groups.reduce((acc: ConditionGroupType[], group) => {
            if (group.id !== groupId) {
                const updatedGroup = {
                    ...group,
                    conditionGroups: removeGroupAndChildren(group.conditionGroups, groupId),
                };
                acc.push(updatedGroup);
            }
            return acc;
        }, []);
    };

    const handleRemoveCondition = (sourceGroupId: string, conditionId: string) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_remove');

        setConditionGroups((prevGroups) => {
            return updateGroupInTree(prevGroups, sourceGroupId, (group) => {
                const updatedConditions = group.conditions.filter(
                    (condition: ConditionType) => condition.id !== conditionId
                );

                updateConditionGroupCount(group, -1);

                return {
                    ...group,
                    conditions: updatedConditions
                };
            });
        });
    };

    const handleAddCondition = (groupId: string, condition?: ConditionType) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        let conditionIndicatorLabel = "";
        if (condition) {
            conditionIndicatorLabel = `${condition.condition.LHS?.indicator.name} ${condition.condition.LHS?.indicator.name === condition.condition.LHS?.field ? "" : condition.condition.LHS?.field} ${condition.condition.conditionType} ${condition.condition.RHS?.indicator.name === condition.condition.RHS?.field ? "" : condition.condition.RHS?.field}`;
        }

        ReactGA.event('condition_add_to_group', {
            condition: conditionIndicatorLabel
        });

        setConditionGroups((prevGroups) => {
            const updatedGroups = prevGroups;
            const { group: targetGroup } = findGroupAndParent(updatedGroups, groupId)!;

            const newCondition: ConditionType = {
                    type: 'condition',
                    chartDetails: {
                        symbol: targetGroup.symbol,
                        periodicity: targetGroup.periodicity,
                        timeframe: targetGroup.timeframe,
                    },
                    condition: {
                        LHS: undefined,
                        conditionType: "",
                        RHS: undefined,
                        isValid: false,
                    },
                    ...condition,
                    sourceGroupId: groupId,
                    id: uuidv4(),
                };

            return updateGroupInTree(prevGroups, groupId, (group) => {
                updateConditionGroupCount(group, 1);
                return {
                    ...group,
                    conditions: group.conditions.concat(newCondition)
                };
            });
        });
    };

    const handleChangeConditionGroup = (groupId: string, key: string, value: any) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_group_change', {
            key: key,
            value: value ? value.toString() : '',
        });

        setConditionGroups((prevGroups) => {
            if (key === "groupTitle") {
                // validate groupTitle does not contain and firebase invalid characters ".", "#", "$", "[", or "]"
                if (value.includes(".") || value.includes("#") || value.includes("$") || value.includes("[") || value.includes("]")) {
                    return [...prevGroups];
                }
            }

            return updateGroupInTree(prevGroups, groupId, (group) => {

                const newGroup = _set(group, key, value)

                newGroup.conditions.forEach((condition) => {
                    condition.chartDetails = {symbol: newGroup.symbol, periodicity: newGroup.periodicity, timeframe: newGroup.timeframe};
                });

                return newGroup
            });
        });
    }

    const handleChangeCondition = (groupId: string, conditionId: string, key: string, value: any) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_change', {
            key: key,
            value: value ? value.toString() : '',
        });

        setConditionGroups((prevGroups) => {
            const updatedGroups = updateConditionInGroup(prevGroups, conditionId, (condition) => {
                return _set(condition, key, value);
            });

            return updatedGroups;
        });
    }

    // Helper function to find a group and its parent group
    const findGroupAndParent = (
        groups: ConditionGroupType[],
        groupId: string,
        parentGroup?: ConditionGroupType,
    ): { group: ConditionGroupType; parentGroup?: ConditionGroupType } | null => {
        for (const group of groups) {
            if (group.id === groupId) {
                return { group, parentGroup };
            }

            const result = findGroupAndParent(group.conditionGroups, groupId, group);
            if (result) {
                return result;
            }
        }

        return null;
    };

    const updateGroupInTree = (
        groups: ConditionGroupType[],
        groupId: string,
        updateFn: (group: ConditionGroupType) => ConditionGroupType
    ): ConditionGroupType[] => {
        return groups.map(group => {
            if (group.id === groupId) {
                // Apply the update function to the group that matches the id
                return updateFn(_cloneDeep(group));
            } else if (group.conditionGroups.length > 0) {
                // Recursively update conditionGroups
                return {
                    ...group,
                    conditionGroups: updateGroupInTree(group.conditionGroups, groupId, updateFn),
                };
            } else {
                // Return the group unchanged if it's not the one we're looking for
                return group;
            }
        });
    };

    const findConditionInGroup = (
        groups: ConditionGroupType[],
        conditionId: string
    ): ConditionType | null => {
        for (const group of groups) {
            // Check if the condition is in the current group's conditions
            const condition = group.conditions.find(c => c.id === conditionId);
            if (condition) {
                return condition;
            }

            // Recursively search in the group's conditionGroups
            const result = findConditionInGroup(group.conditionGroups, conditionId);
            if (result) {
                return result;
            }
        }

        return null;
    };

    const updateConditionInGroup = (
        groups: ConditionGroupType[],
        conditionId: string,
        updateFn: (condition: ConditionType) => ConditionType
    ): ConditionGroupType[] => {
        return groups.map(group => {
            // Check if the condition is in the current group's conditions
            const updatedConditions = group.conditions.map(condition => {
                if (condition.id === conditionId) {
                    // Apply the update function to the condition that matches the id
                    return updateFn(_cloneDeep(condition));
                } else {
                    return condition;
                }
            });

            // Recursively update conditionGroups
            const updatedConditionGroups = updateConditionInGroup(group.conditionGroups, conditionId, updateFn);

            // Return the updated group
            return {
                ...group,
                conditions: updatedConditions,
                conditionGroups: updatedConditionGroups,
            };
        });
    };

    const handleDrop = (groupId: string, item: any, dropIndex: number) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        if (item.type === 'condition') {
            // Move condition
            setConditionGroups((prevGroups) => {
                // Add condition to the target group
                const { group: targetGroup } = findGroupAndParent(prevGroups, groupId)!;
                const { group: sourceGroup } = findGroupAndParent(prevGroups, item.sourceGroupId)!;

                // Check if dropIndex is valid, otherwise add to the end
                if (targetGroup.id !== item.sourceGroupId || (dropIndex >= 0 && dropIndex <= targetGroup.conditions.length)) {

                    // Add condition to the target group
                    let updatePrevGroup = updateGroupInTree(prevGroups, targetGroup.id, (group) => {
                        updateConditionGroupCount(group, 1);
                        const condition = findConditionInGroup(prevGroups, item.id)!;
                        condition.sourceGroupId = group.id;
                        condition.chartDetails = {symbol: group.symbol, periodicity: group.periodicity, timeframe: group.timeframe};

                        const existingConditionIndex = group.conditions.findIndex((condition: any) => condition.id === item.id);
                        if (existingConditionIndex > -1) {
                            group.conditions.splice(existingConditionIndex, 1);
                        }

                        group.conditions.splice(dropIndex, 0, condition)

                        return group
                    });

                    if (targetGroup.id !== item.sourceGroupId) {
                        // remove from the source group
                        updatePrevGroup = updateGroupInTree(updatePrevGroup, sourceGroup.id, (group) => {
                            updateConditionGroupCount(group, -1);
                            return {
                                ...group,
                                conditions: group.conditions.filter((condition: any) => condition.id !== item.id),
                            }
                        });
                    }

                    return updatePrevGroup;
                }

                return _cloneDeep(prevGroups);
            });
        } else if (item.type === 'group') {
            // Move condition group
            if (groupId === item.sourceGroupId) return;

            setConditionGroups((prevGroups) => {
                // Add the condition group to the target group
                const { group: targetGroup } = findGroupAndParent(prevGroups, groupId)!;
                const { group: movingGroup } = findGroupAndParent(prevGroups, item.id)!;

                let addedConditionGroupMaxDepth = findMaxDepth(movingGroup);
                if (targetGroup.depth + addedConditionGroupMaxDepth > MAX_CONDITION_GROUP_DEPTH) {
                    return [...prevGroups];
                }

                // Find and remove the condition group from the source group
                const { parentGroup: sourceParentGroup } = findGroupAndParent(prevGroups, item.id)!;


                if (sourceParentGroup) {
                    // if not a root condition group, decrement the count of the parent group
                    updateConditionGroupCount(sourceParentGroup, -1);
                }
                // increment the count of the target group
                updateConditionGroupCount(targetGroup, 1);


                // Move condition group item to the target group

                const groupIndex = sourceParentGroup ? sourceParentGroup.conditionGroups.findIndex(
                    (group: ConditionGroupType) => group.id === item.id,
                ) : prevGroups.findIndex((group) => group.id === item.id);

                const [removedGroup] = sourceParentGroup ? sourceParentGroup.conditionGroups.splice(
                    groupIndex,
                    1,
                ) : prevGroups.splice(groupIndex, 1);


                removedGroup.depth = targetGroup.depth + 1;
                targetGroup.conditionGroups.splice(dropIndex, 0, removedGroup);

                return _cloneDeep(prevGroups);
            });
        }
    };

    const handleConditionDuplicate = (groupId: string, conditionId: string) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_duplicate');

        setConditionGroups((prevGroups) => {
            const { group: sourceGroup } = findGroupAndParent(prevGroups, groupId)!;

            const condition = findConditionInGroup(prevGroups, conditionId)!;

            const newCondition = {
                ..._cloneDeep(condition),
                id: uuidv4(),
            };

            return updateGroupInTree(prevGroups, sourceGroup.id, (group) => {
                updateConditionGroupCount(group, 1);
                return {
                    ...group,
                    conditions: group.conditions.concat(newCondition)
                };
            });
        });
    }

    const handleConditionGroupDuplicate = (groupId: string) => {
        const [, setConditionGroups] = getActiveConditionGroups();

        ReactGA.event('condition_group_duplicate');

        setConditionGroups((prevGroups) => {
            const { parentGroup: sourceParentGroup, group } = findGroupAndParent(prevGroups, groupId)!;

            const newGroup = _cloneDeep(group);
            updateGroupIds(newGroup);

            if (sourceParentGroup) {
                return updateGroupInTree(prevGroups, sourceParentGroup.id, (group) => {
                    updateConditionGroupCount(group, 1);
                    return {
                        ...group,
                        conditionGroups: group.conditionGroups.concat(newGroup)
                    };
                });
            } else {
                return [...prevGroups, newGroup];
            }
        });
    }

    const updateGroupIds = (group: ConditionGroupType) => {
        // Update the group's id
        group.id = uuidv4();

        // Update all conditions within this group
        for (const condition of group.conditions) {
            condition.sourceGroupId = group.id;
        }

        const newDepth = group.depth + 1;
        // Recursively update all nested condition groups
        for (const nestedGroup of group.conditionGroups) {
            nestedGroup.depth = newDepth
            updateGroupIds(nestedGroup);
        }
    };

    const handleConditionGroupCopyTo = (groupId: string) => {

        const oppositeConditionGroups: any = activeTab === 'buy' ? [sellConditionGroups, setSellConditionGroups] : [buyConditionGroups, setBuyConditionGroups];
        const [conditionGroups] = getActiveConditionGroups();
        const { parentGroup: sourceParentGroup } = findGroupAndParent(conditionGroups, groupId)!;

        ReactGA.event('condition_group_copy_to');

        oppositeConditionGroups[1]((prevGroups: ConditionGroupType[]) => {

            const groupIndex = sourceParentGroup ? sourceParentGroup.conditionGroups.findIndex(
                (group: ConditionGroupType) => group.id === groupId,
            ) : conditionGroups.findIndex((group) => group.id === groupId);

            const group = sourceParentGroup ? _cloneDeep(sourceParentGroup.conditionGroups[groupIndex]) : _cloneDeep(conditionGroups[groupIndex]);

            const newGroup = {
                ...group,
                depth: 1,
            };

            updateGroupIds(newGroup);

            prevGroups = [newGroup, ...prevGroups];

            return _cloneDeep(prevGroups);
        })
    }

    function updateConditionGroupCount(group: ConditionGroupType, delta: number = 0) {
        const groupMaxCount = (group.conditionGroups?.length ?? 0) + (group.conditions?.length ?? 0)
        if (group.count === groupMaxCount) {
            group.count += delta;
        }
    }

    return (
        <PatternTreeContext.Provider
            value={{
                handleDrop,
                handleChangeCondition,
                handleChangeConditionGroup,
                handleAddCondition,
                handleRemoveCondition,
                handleRemoveConditionGroup,
                handleConditionDuplicate,
                addConditionGroup,
                handleAddNestedGroup,
                buyConditionGroups,
                sellConditionGroups,
                setSellConditionGroups,
                setBuyConditionGroups,
                conditionGroups: getActiveConditionGroups()[0],
                setConditionGroups: getActiveConditionGroups()[1],
                setActiveTab,
                activeTab,
                handleConditionGroupCopyTo,
                handleConditionGroupDuplicate
            }}>
            {children}
        </PatternTreeContext.Provider>
    )
}


interface patternModifers {
    conditionGroups: ConditionGroupType[];
    setConditionGroups: (groups: ConditionGroupType[]) => void;
    handleDrop: (groupId: string, item: any, dropIndex: number) => void;
    handleRemoveCondition: (groupId: string, conditionId: string) => void;
    handleRemoveConditionGroup: (groupId: string) => void;
    handleChangeCondition: (groupId: string, conditionId: string, key: string, value: any) => void;
    handleChangeConditionGroup: (groupId: string, key: string, value: any) => void;
    handleConditionDuplicate: (groupId: string, conditionId: string) => void;
    handleAddCondition: (groupId: string, condition?: ConditionType) => void;
    addConditionGroup: (conditionGroup?: ConditionGroupType) => void;
    handleAddNestedGroup: (groupId: string, conditionGroup?: ConditionGroupType) => void;
    buyConditionGroups: ConditionGroupType[];
    sellConditionGroups: ConditionGroupType[];
    setSellConditionGroups: (groups: ConditionGroupType[]) => void;
    setBuyConditionGroups: (groups: ConditionGroupType[]) => void;
    setActiveTab: (tab: string) => void;
    activeTab: string;
    handleConditionGroupCopyTo: (groupId: string) => void;
    handleConditionGroupDuplicate: (groupId: string) => void;
}

export const PatternTreeContext = createContext<patternModifers>({} as patternModifers);
